import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { db, auth } from "./utils/firebase";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

import "./App.css";
// context
import { ThemeProvider } from "./context/useTheme";
import { Shell } from "./pages/Shell";
import { Signin } from "./pages/Signin";
import { Signup } from "./pages/Signup";
import { Verification } from "./pages/Verification";
import { Dashboard } from "./pages/Dashboard";
import { Profile } from "./pages/Profile";
import { Setting } from "./pages/Setting";
import { Trade } from "./pages/Trade";
import { Check } from "./pages/Check";
import { Data } from "./pages/Data";
import { TransactionView } from "./pages/TransactionView";
import { Market } from "./pages/Market";
import { Onboarding } from "./pages/Onboarding";
import { Deposit } from "./pages/Deposit";
import SendMobile from "./components/Send/SendMobile";
import Receive from "./components/Receive";
import { AuthProvider } from "./context/useAuth";
import { Spinner } from "./components/Spinner";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Transaction } from "./pages/Transaction";
import Notifications from "./components/Notifications";
import { Bot } from "./pages/Bot";
import { IndividualBot } from "./pages/Bot/IndividualBot";
//import {useViewportHeight} from "./hooks/useViewportHeight"
import { WidgetLayout } from "./pages/Widget/layout";
import { WidgetAuth } from "./pages/Widget/auth";
import { WidgetInput } from "./pages/Widget/input";
import { WidgetVerification } from "./pages/Widget/auth_verification";
// import { useFirebaseMessaging } from "./hooks/useFirebaseMessaging";
import { messaging } from "./utils/firebase";
import { onMessage } from "firebase/messaging";
import { logos } from "./assets";

function App() {
  // useFirebaseMessaging()

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [user, setUser] = useState({});
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      // Function to display notifications
      const showNotification = (title, body) => {
        if (Notification.permission === "granted") {
          new Notification(title, {
            body: body,
            icon: logos.Logo192,
            requireInteraction: true
          });
        }
      };

      // console.log("Message received in foreground: ", payload);
      const notificationTitle = payload.data?.title || "New Message";
      const notificationBody =
        payload.data?.body || "You have a new notification";

      // Show notification
      showNotification(notificationTitle, notificationBody);
    });

    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        const refreshToken = user.refreshToken;
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("refreshToken", refreshToken);

        setUserData(user);
        const uid = user.uid;
        const emailVerified = user?.emailVerified || false;

        if (emailVerified) {
          //setVerified(true);
        }

        if (uid && emailVerified) {
          const pathToUserData = `users/${uid}`;
          const userDocRef = doc(db, pathToUserData);

          const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              let tempUser = docSnapshot.data();
              setUser(tempUser);
            } else {
              console.log("No such document!");
            }
          });

          const pathToDocuments = `users/${user.uid}/accounts`;
          const accountUnsubscribe = onSnapshot(
            collection(db, pathToDocuments),
            (querySnapshot) => {
              const accounts = [];
              querySnapshot.forEach((doc) => {
                accounts.push({ ...doc.data(), uid: doc.id });
              });
              accounts.sort((a, b) => a.accountIndex - b.accountIndex);
              setAccount(accounts[0]);
              setLoading(false);
            }
          );

          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            await updateDoc(userDocRef, {
              lastLogin: new Date(),
              emailVerified: emailVerified,
            });
          }

          return () => {
            unsubscribe();
            accountUnsubscribe();
          };
        }
        setLoading(false);
      } else {
        setUserData(null);
        setAccount({});
        setUser({});
        setVerified(false);
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    //to check if email is verified
    if (userData) {
      if (userData.emailVerified) {
        setVerified(true);
      }
    }
  }, [userData]);

  if (loading) {
    return (
      <ThemeProvider>
        <Spinner loading={loading} />
      </ThemeProvider>
    );
  }

  if (!verified && userData) {
    return (
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/swap" element={<WidgetLayout />}>
              <Route index element={<WidgetInput account={account} />} />
              <Route
                path="signin"
                element={<WidgetAuth account={account} mode="in" />}
              />
              <Route
                path="signup"
                element={<WidgetAuth account={account} mode="up" />}
              />
              <Route
                path="reset"
                element={<WidgetAuth account={account} mode="reset" />}
              />
              <Route
                path="verification"
                element={
                  <WidgetVerification
                    verified={verified}
                    setVerified={setVerified}
                  />
                }
              />
            </Route>
            <Route
              path="/*"
              element={
                <Verification verified={verified} setVerified={setVerified} />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <Notifications user={user} />
        <Router>
          <Routes>
            <Route path="/swap" element={<WidgetLayout />}>
              <Route index element={<WidgetInput account={account} />} />
              <Route
                path="signin"
                element={<WidgetAuth account={account} mode="in" />}
              />
              <Route
                path="signup"
                element={<WidgetAuth account={account} mode="up" />}
              />
              <Route
                path="reset"
                element={<WidgetAuth account={account} mode="reset" />}
              />
            </Route>
            <Route
              path="/"
              element={userData ? <Navigate to="/app" /> : <Signin />}
            />
            <Route path="/sign-in" element={<Signin />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route
              path="/app"
              element={userData ? <Shell /> : <Navigate to="/sign-up" />}
            >
              <Route
                index
                element={<Dashboard account={account} user={user} />}
              />
              <Route
                path="deposit"
                element={<Deposit account={account} setLoading={setLoading} />}
              />
              <Route
                path="profile"
                element={<Profile account={account} user={user} />}
              />
              <Route path="setting" element={<Setting />} />
              <Route
                path="trade"
                element={<Trade user={user} account={account} />}
              />
              <Route
                path="trade/:tradeId"
                element={<Trade account={account} />}
              />
              <Route path="data/:dataId" element={<Data />} />
              <Route path="check" element={<Check />} />
              <Route
                path="transactions"
                element={<TransactionView account={account} />}
              />
              <Route
                path="transactions/:txId"
                element={<Transaction account={account} />}
              />
              <Route path="market" element={<Market />} />
              <Route path="onboarding" element={<Onboarding />} />
              <Route
                path="send"
                element={<SendMobile account={account} user={user} />}
              />
              <Route path="receive" element={<Receive account={account} />} />
              <Route path="bot" element={<Bot />} />
              <Route path="bot/:walletAddress" element={<IndividualBot />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
