import React from "react"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"
import { useGetTransactions } from "../../hooks/useGetTransactions"
import { TransactionLoader } from "../../components/ContentLoader"
import { useNavigate } from "react-router-dom" // Import useNavigate

export const Transactions = ({ account }) => {
  const { txs, loading } = useGetTransactions(account)
  // const theme = useTheme()
  const navigate = useNavigate() // Initialize useNavigate

  const ImageFormat = ({ send, receive }) => {
    return (
      <div className="relative h-8 w-8">
        {send && (
          <img
            src={send}
            alt="Send"
            className="h-8 w-8 rounded-full absolute inset-0 z-10"
          />
        )}
        {receive && (
          <img
            src={receive}
            alt="Receive"
            className={`h-8 w-8 rounded-full ${
              send ? `absolute inset-0 translate-x-4 z-10` : ``
            }`}
          />
        )}
        {!send && !receive && (
          <div className="h-8 w-8 rounded-full bg-gray-200"></div>
        )}
      </div>
    )
  }

  const SortedEvents = ({ summaryWithPrices, tx }) => {
    const numberOfEvents = summaryWithPrices.length

    if (numberOfEvents === 1) {
      const receiveEvent = summaryWithPrices[0]
      const elipsisAddress = "wallet ..." + receiveEvent.from.slice(-4)

      if (receiveEvent.amountUi === 0) return <></>

      return (
        <div
          onClick={() => navigate(`${tx.uid}`)} // Navigate on click
          className="flex flex-col imd:flex-row gap-1 imd:gap-0 px-2 imd:px-4 py-2 text-xs md:text-sm rounded-2xl my-1 imd:mx-2 items-center cursor-pointer bg-panelColor2-light dark:bg-panelColor2-dark"
        >
          <div className="font-semibold w-full imd:w-[20%]">Receive</div>
          <div className="w-full imd:w-[80%] flex flex-row items-center">
            <div className="w-[20%]">
              <ImageFormat receive={receiveEvent.logo} />
            </div>
            <div className="w-[30%] imd:w-[25%] pl-2 imd:pl-0">
              <a
                href={`https://solscan.io/address/${receiveEvent.from}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {elipsisAddress}
              </a>
            </div>
            <div className="w-[30%] imd:w-[25%] flex flex-col">
              <div>{receiveEvent.symbol} </div>
              <div>{receiveEvent.amountUi}</div>
            </div>
            <div className="w-[20%] imd:w-[25%] font-semibold flex items-center justify-start text-green-700">
              +${receiveEvent?.value.toFixed(2)}
            </div>
            <div className="w-[5%] hidden imd:flex items-center justify-center">
              <a
                href={`https://solscan.io/tx/${tx.uid}`}
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center"
              >
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 sm:h-6 sm:w-6 cursor-pointer"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      )
    }

    if (numberOfEvents === 2) {
      // const networkFeeEvent = summaryWithPrices.find(
      //   (event) => event.type === "Transaction Fee"
      // )
      const sendEvent = summaryWithPrices.find(
        (event) => event.type === "Token Transfer"
      )
      const elipsisAddress = "wallet ..." + sendEvent.to.slice(-4)

      return (
        <div
          onClick={() => navigate(`${tx.uid}`)} // Navigate on click
          className="flex flex-col imd:flex-row gap-1 imd:gap-0 px-2 imd:px-4 py-2 text-xs md:text-sm rounded-2xl my-1 imd:mx-2 items-center cursor-pointer bg-panelColor2-light dark:bg-panelColor2-dark"
        >
          <div className="font-semibold w-full imd:w-[20%]">Send</div>
          <div className="w-full imd:w-[80%] flex flex-row items-center">
            <div className="w-[20%]">
              <ImageFormat send={sendEvent.logo} />
            </div>
            <div className="w-[30%] imd:w-[25%] flex flex-col pl-2 imd:pl-0">
              <div>{sendEvent.symbol} </div>
              <div>{sendEvent.amountUi}</div>
            </div>
            <div className="w-[30%] imd:w-[25%]">
              <a
                href={`https://solscan.io/address/${sendEvent.to}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {elipsisAddress}
              </a>
            </div>
            <div className="w-[20%] imd:w-[25%] font-semibold  text-red-800 flex justify-start items-center">
              -${sendEvent?.value.toFixed(2)}
            </div>
            <div className="w-[5%] hidden imd:flex items-center justify-center">
              <a
                href={`https://solscan.io/tx/${tx.uid}`}
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center"
              >
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 sm:h-6 sm:w-6 cursor-pointer"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      )
    }

    if (numberOfEvents >= 4) {
      const sendEvent = summaryWithPrices.find(
        (event) => event.type === "Token Transfer" && event.direction === "sent"
      )
      const receiveEvent = summaryWithPrices.find(
        (event) =>
          event.type === "Token Transfer" && event.direction === "received"
      )
      // const networkFeeEvent = summaryWithPrices.find(
      //   (event) => event.type === "Transaction Fee"
      // );
      // const platformFeeEvent = summaryWithPrices.find(
      //   (event) => event.type === "Platform Fee"
      // );
      return (
        <div
          onClick={() => navigate(`${tx.uid}`)} // Navigate on click
          className="flex flex-col imd:flex-row gap-1 imd:gap-0 px-2 imd:px-4 py-2 text-xs md:text-sm rounded-2xl my-1 imd:mx-2 items-center cursor-pointer bg-panelColor2-light dark:bg-panelColor2-dark"
        >
          <div className="font-semibold w-full imd:w-[20%]">Swap</div>
          <div className="w-full imd:w-[80%] flex flex-row items-center">
            <div className="w-[20%]">
              <ImageFormat send={sendEvent.logo} receive={receiveEvent.logo} />
            </div>
            <div className="w-[30%] imd:w-[25%] flex flex-col pl-2 imd:pl-0">
              <div>{sendEvent.symbol} </div>
              <div>{sendEvent?.amountUi.toFixed(2)}</div>
            </div>
            <div className="w-[30%] imd:w-[25%] flex flex-col">
              <div>{receiveEvent.symbol} </div>
              <div>{receiveEvent?.amountUi.toFixed(2)}</div>
            </div>
            <div className="w-[20%] imd:w-[25%] font-semibold flex justify-start items-center">
              -${sendEvent?.value.toFixed(2)}
            </div>
            <div className="w-[5%] hidden imd:flex items-center justify-center">
              <a
                href={`https://solscan.io/tx/${tx.uid}`}
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center"
              >
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 sm:h-6 sm:w-6 cursor-pointer"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      )
    }

    return <> </>
  }

  return (
    <div className="lg:mt-0 flex flex-col w-full border-none rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
      {loading && <TransactionLoader />}
      {!loading && (
        <>
          <div className="w-full">
            <p className="md:hidden text-xl md:text-2xl font-bold py-4 md:px-0 px-3 text-center">
              Recent Transactions
            </p>
          </div>

          <div className="relative flex flex-col min-w-0 break-words w-full h-full rounded-xl p-2 md:px-5">
            <div className="hidden md:flex rounded-t mb-0 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full max-w-full flex-grow flex-1">
                  <p className="text-xl md:text-2xl font-bold py-4 md:px-0 px-3">
                    Recent Transactions
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full overflow-x-hidden overflow-y-auto ">
              {txs.map((tx, key) => {
                const humanDate = tx.date
                return (
                  <div key={key} className="mb-3">
                    <div className="flex items-center justify-between">
                      <div className="text-[16px] md:text-lg font-semibold pl-3">
                        {humanDate}
                      </div>
                    </div>
                    <div className="flex flex-col text-xs md:text-sm">
                      {tx.transactions.map((tx, key) => {
                        return (
                          <SortedEvents
                            summaryWithPrices={tx.summaryWithPrices}
                            tx={tx}
                            key={key}
                          />
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
