import { useState, useEffect } from "react"
import { getApprovedTokens } from "../../hooks/handleApprovedTokens"

export const SelectToken = ({
  tokenSymbol,
  status,
  handleModal,
  direction = "col",
}) => {
  const [approvedTokensList, setApprovedTokensList] = useState({})
  useEffect(() => {
    let isMounted = true

    const fetchTokens = async () => {
      const res = await getApprovedTokens()
      if (isMounted) setApprovedTokensList(res)
    }

    fetchTokens()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div
      className={`w-full flex ${
        direction === "col" ? "flex-col gap-1" : "flex-row gap-3"
      } justify-center items-center p-1 rounded-lg ${
        !status
          ? "hover:cursor-not-allowed"
          : "hover:cursor-pointer hover:bg-panelColor-light dark:hover:bg-panelColor-dark"
      }`}
      onClick={() => {
        if (status) handleModal(true)
      }}
    >
      {/* Selected Token */}
      <img
        src={
          Object.keys(approvedTokensList).length > 0
            ? approvedTokensList[tokenSymbol] === undefined
              ? approvedTokensList["SOL"]["logo"]
              : approvedTokensList[tokenSymbol]["logo"]
            : ""
        }
        className="rounded-full w-10"
      />
      <p className="text-center text-[12px]">
        {approvedTokensList[tokenSymbol] === undefined ? "SOL" : tokenSymbol}
      </p>
    </div>
  )
}
