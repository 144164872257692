import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { images } from "../../assets"
import { useTheme } from "../../context/useTheme"
import { WidgetSignIn } from "./auth_in"
import { WidgetSignUp } from "./auth_up"
import { WidgetForgotPassword } from "./auth_forgot_password"

export const WidgetAuth = ({ account, mode }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    if (Object.keys(account).length > 0) navigate("/swap")
    // eslint-disable-next-line
  }, [account])

  return (
    <div className="flex w-full h-full px-4 py-6">
      <div className="flex w-full flex-col my-auto items-center gap-2">
        <img
          src={
            theme.currentWidgetTheme === "light"
              ? images.MemexLogo
              : images.MemexWhiteLogo
          }
          className="w-2/3"
          alt="Logo"
        />
        <div className="w-full">
          {mode === "in" && <WidgetSignIn />}
          {mode === "up" && <WidgetSignUp />}
          {mode === "reset" && <WidgetForgotPassword />}
        </div>
      </div>
    </div>
  )
}
