import { convertToMegaAndKilo } from "../../utils/formats"

export const InputAmount = ({
  mode,
  amount,
  handleAmount,
  baseTokenBalance,
  status,
}) => {
  const value = amount === 0 ? "" : amount
  const handleChange = (e) => {
    handleAmount(e.target.value)
  }
  return (
    <>
      {mode === "Swap" ? (
        <input
          type="text"
          disabled={true}
          value={convertToMegaAndKilo(baseTokenBalance["amount"], 4)}
          onChange={() => {}}
          placeholder="0.000"
          className="bg-transparent border-0 text-center p-0 text-[30px] w-full mb-1 focus:outline-none focus:ring-0"
        />
      ) : (
        // <span className="text-center text-[34px]">
        //   {convertToMegaAndKilo(
        //     Number((baseTokenUSDPrice + targetTokenUSDPrice).toFixed(2))
        //   )}
        //   $
        // </span>
        <input
          type="number"
          id="floatInput"
          name="floatInput"
          step="any"
          disabled={!status}
          value={value}
          onChange={handleChange}
          placeholder="0.000"
          className="bg-transparent border-t-0 border-l-0 border-r-0 border-b-1 focus:border-b-themeColor-light text-center p-0 text-[30px] w-2/3 mb-1 focus:outline-none focus:ring-0"
        />
      )}
    </>
  )
}
