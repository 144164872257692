import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"

const container = document.getElementById("root")
const root = createRoot(container) // Create a root.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// Register the service worker
if ("serviceWorker" in navigator) {
  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Service Worker registered with scope:", registration.scope)

      // Wait for the service worker to be ready and then subscribe for push notifications
      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              "BMesum0PG7Na6LkXZTTDijR3CgordC0WZFkYXCBxz74MTwxPjqLJROIMUMejkP7zXVLcqoVUqgqZi5-8vGJmhq0"
            ),
          })
          .then(function (subscription) {
            // Handle the subscription, and listen for incoming push events
            registration.addEventListener("push", function (event) {
              const data = event.data.json() // Assuming your payload is JSON
              console.log("Push message received:", data)

              // If the app is in the foreground, update UI directly, or handle differently
              if (document.visibilityState === "visible") {
                // For example, update UI
                console.log(
                  "App is in the foreground, updating UI with push message."
                )
              } else {
                // Show a notification if the app is not in the foreground
                registration.showNotification(data.title, {
                  body: data.body,
                  icon: "/path/to/icon.png",
                })
              }
            })
          })
          .catch(function (error) {
            console.log("Failed to subscribe:", error)
          })
      })
    })
    .catch(function (error) {
      console.log("Service Worker registration failed:", error)
    })
} else {
  console.log("Service Worker not supported in this browser.")
}
