import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { db, auth } from "../../utils/firebase"
import { doc, onSnapshot } from "firebase/firestore"
import { ArrowsRightLeftIcon, ArrowPathIcon } from "@heroicons/react/24/outline"
import { Cog8ToothIcon } from "@heroicons/react/20/solid"
import { SelectToken } from "./SelectToken"
import { TokenBalance } from "./TokenBalance"
import { WalletAddress } from "./WalletAddress"
import { TokensListModal } from "./TokensListModal"
import { StartSwap } from "./StartSwap"
import { SelectMode } from "./SelectMode"
import { PercentageTabs } from "./PercentageTabs"
import { InputAmount } from "./InputAmount"
import { images } from "../../assets"
import { getBalanceDirect } from "../../hooks/functions"
import { useTheme } from "../../context/useTheme"
import { SettingModal } from "./SettingModal"

export const BotCard = ({ walletAddress, tokenList }) => {
  const [status, setStatus] = useState(true) // True - not running, False - running
  const [data, setData] = useState({
    baseToken: "",
    baseTokenBalance: 0,
    direction: true,
    duration: 1,
    index: 0,
    status: true,
    targetToken: "",
    targetTokenBalance: 0,
    walletAddress: "",
    mode: "",
    inputAmount: 0,
    outputAmount: 0,
  })
  const [baseToken, setBaseToken] = useState()
  const [targetToken, setTargetToken] = useState()
  const [baseTokenBalance, setBaseTokenBalance] = useState({
    amount: 0,
    price: 0,
  })
  const [showBaseTokensModal, setShowBaseTokensModal] = useState(false)
  const [showTargetTokensModal, setShowTargetTokensModal] = useState(false)
  const [targetTokenBalance, setTargetTokenBalance] = useState({
    amount: 0,
    price: 0,
  })
  const [direction, setDirection] = useState(true) // True - Result is base token, False - Result is target token
  const [error, setError] = useState("")
  const [mode, setMode] = useState("")
  const [inputAmount, setInputAmount] = useState(0)
  const [solBalance, setSolBalance] = useState(0)
  const [duration, setDuration] = useState(1)
  // const [loading, setLoading] = useState(false)
  const [settingModal, setSettingModal] = useState(false)

  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    let isMounted = true
    // if (isMounted) setLoading(true)

    // Load Bot Info
    const currentUser = auth.currentUser
    const pathToBots = `bots/${currentUser?.uid}/botDetails/${walletAddress}`
    const unsubscribeBots = onSnapshot(doc(db, pathToBots), (docSnapshot) => {
      const botInfo = docSnapshot.data()

      if (!botInfo || Object.keys(botInfo).length === 0) {
        setData({})
      } else {
        setData(botInfo)
      }

      // if (isMounted) setLoading(false)
    })

    return () => {
      isMounted = false
      unsubscribeBots()
    }
  }, [walletAddress])

  useEffect(() => {
    setStatus(data.status)
    setError("")
  }, [data.status])

  useEffect(() => {
    setBaseToken(data.baseToken)
  }, [data.baseToken])

  useEffect(() => {
    setTargetToken(data.targetToken)
  }, [data.targetToken])

  // useEffect(() => {
  //   setDirection(info.direction);
  // }, [info.direction]);

  useEffect(() => {
    if (data.mode) {
      setMode(data.mode)
    } else {
      setMode("Swap")
    }
  }, [data.mode])

  // useEffect(() => {
  //   if (info.duration) {
  //     setDuration(info.duration);
  //   } else {
  //     setDuration(1);
  //   }
  // }, [info.duration]);

  useEffect(() => {
    if (mode) {
      setError("")
    }
  }, [mode])

  useEffect(() => {
    const fetchSOL = async () => {
      const res = await getBalanceDirect(
        walletAddress,
        "So11111111111111111111111111111111111111112",
        "sol"
      )
      if (res && res.decimals !== undefined) {
        setSolBalance(
          Number(res["amount"] / Math.pow(10, res["decimals"])).toFixed(4)
        )
      } else {
        setSolBalance(0)
      }
    }

    fetchSOL()
  }, [walletAddress])

  // useEffect(() => {
  //   if (mode === "AMM" && status) {
  //     if (inputAmount > baseTokenBalance["amount"]) {
  //       setError("Balance Error");
  //     } else {
  //       setError("");
  //     }
  //   }
  // }, [inputAmount, mode, baseTokenBalance]);

  // Open an individual bot
  const openBot = () => {
    navigate(`/app/bot/${walletAddress}`)
  }

  // Swap base token and target token
  const revertTokens = () => {
    const tempToken = baseToken
    setBaseToken(targetToken)
    setTargetToken(tempToken)
  }

  const toggleSettingModal = () => {
    setSettingModal(true)
  }

  return (
    <div className="w-full max-w-[300px] rounded-xl flex flex-col justify-center items-stretch bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
      {/* Top Part */}
      <div className="flex flex-col rounded-t-xl px-4 lp:px-5 pc:px-3 pt-4 justify-center items-stretch bg-panelColor2-light dark:bg-panelColor2-dark">
        {/* Header */}
        <div className="text-xs flex items-center justify-between">
          <img
            src={
              theme.currentTheme === "light"
                ? images.MemexLogo
                : images.MemexWhiteLogo
            }
            className="h-6"
            alt="MemexLogo"
          />
          <Cog8ToothIcon
            className="h-6 w-6 cursor-pointer"
            aria-hidden="true"
            onClick={toggleSettingModal}
          />
        </div>

        {/* Total Balance in USD */}
        <div className="w-full flex justify-center mt-4 h-[50px]">
          <InputAmount
            mode={mode}
            amount={inputAmount}
            baseTokenBalance={baseTokenBalance}
            handleAmount={setInputAmount}
            status={status}
          />
        </div>

        {/* Available SOL */}
        <p className="text-center mb-1 text-sm">
          <span className="mr-2">Available SOL:</span> {solBalance}
        </p>

        {/* Percentage Tabs */}
        <div className="w-full justify-center text-center mb-3">
          <PercentageTabs
            status={status}
            mode={mode}
            handleAmount={setInputAmount}
            baseToken={baseToken}
            tokenList={tokenList}
            walletAddress={walletAddress}
          />
        </div>

        {/* Tokens */}
        <div className="flex flex-row gap-2 justify-center items-center">
          {/* Base Token */}
          <div className="w-[40%]">
            <SelectToken
              tokenSymbol={baseToken}
              status={status}
              handleModal={setShowBaseTokensModal}
            />
          </div>

          <div className="w-[20%] flex justify-center items-center">
            {status ? (
              <ArrowsRightLeftIcon
                className="w-12 h-12 hover:cursor-pointer hover:bg-panelColor-light dark:hover:bg-panelColor-dark p-2 rounded-md"
                onClick={revertTokens}
              />
            ) : (
              <ArrowPathIcon className="w-12 h-12 p-2 animate-spin" />
            )}
          </div>

          {/* Target Token */}
          <div className="w-[40%]">
            <SelectToken
              tokenSymbol={targetToken}
              status={status}
              handleModal={setShowTargetTokensModal}
            />
          </div>
        </div>

        {/* Select Mode */}
        <div className="w-full flex my-1">
          <span className="text-[12px] w-12">Mode :</span>
          <SelectMode
            selectedMode={mode}
            handleMode={setMode}
            status={status}
          />
        </div>

        {/* Result Token */}
        {/* <div className="w-full flex my-1">
          <span className="text-[12px] w-12">Result :</span>
          <ResultToken
            baseTokenSymbol={baseToken}
            targetTokenSymbol={targetToken}
            direction={direction}
            handleDirection={setDirection}
            status={status}
          />
        </div> */}

        {/* Select Duration */}
        {/* <div className="w-full flex my-1">
          <span className="text-[12px] w-12">Time :</span>
          <SelectDuration
            status={status}
            selectedDuration={duration}
            handleDuration={setDuration}
          />
        </div> */}
      </div>

      {/* Bottom Part */}
      <div className="flex flex-col rounded-b-xl px-4 lp:px-5 pc:px-3 pb-4 justify-center items-stretch">
        {/* Wallet Address */}
        <div className="my-1">
          <WalletAddress address={walletAddress} />
        </div>

        <hr />

        {/* Tokens Balance */}
        <div className="w-full">
          {/* Base Token */}
          <TokenBalance
            tokenSymbol={baseToken}
            handleBalance={setBaseTokenBalance}
            balance={Number(data.baseTokenBalance)}
            status={status}
            walletAddress={walletAddress}
            tokenList={tokenList}
          />

          {/* Target Token */}
          <TokenBalance
            tokenSymbol={targetToken}
            handleBalance={setTargetTokenBalance}
            balance={Number(data.targetTokenBalance)}
            status={status}
            walletAddress={walletAddress}
            tokenList={tokenList}
          />
        </div>

        {/* Alert */}
        <div className="w-full text-center text-[16px] min-h-6 text-red-500">
          {error}
        </div>

        {/* Actions */}
        <div className="w-full flex justify-center mt-2">
          <StartSwap
            direction={direction}
            baseToken={baseToken}
            targetToken={targetToken}
            status={status}
            error={error}
            handleError={setError}
            walletAddress={walletAddress}
            mode={mode}
            amount={inputAmount}
            tokenList={tokenList}
            duration={duration}
          />
        </div>
        <p
          onClick={openBot}
          className="mt-2 underline text-center text-[14px] hover:opacity-70 hover:cursor-pointer"
        >
          View Details
        </p>
      </div>

      {/* Tokens List Modal for Target token */}
      <TokensListModal
        setSelectedToken={setTargetToken}
        showStatus={showTargetTokensModal}
        setShowStatus={setShowTargetTokensModal}
        tokenList={tokenList}
      />

      {/* Tokens List Modal for Base token */}
      <TokensListModal
        setSelectedToken={setBaseToken}
        showStatus={showBaseTokensModal}
        setShowStatus={setShowBaseTokensModal}
        tokenList={tokenList}
      />

      <SettingModal
        openModal={settingModal}
        setOpenModal={setSettingModal}
        tokenList={tokenList}
        baseToken={baseToken}
        targetToken={targetToken}
      />
    </div>
  )
}
