import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"
import { BackButton } from "../../components/Buttons"
import { WidgetOnramper } from "./onramper"
import { WidgetCoinbaseButton } from "./coinbase"
import { useState } from "react"
import { images } from "../../assets"

export const WidgetDeposit = ({ account, handleSteps, usdValue }) => {
  const theme = useTheme()

  const [onramperState, setOnramperState] = useState(0)

  return (
    <div className="flex flex-col w-full h-full justify-center gap-3 px-4 py-6">
      <div className="w-full flex-grow flex flex-col gap-3 justify-center items-center">
        {onramperState === 0 && (
          <>
            <div className="font-bold text-[18px] md:text-2xl mt-3 text-center">
              How do you want to deposit?
            </div>
            <div className="border rounded-xl w-[70%] flex flex-col items-center justify-center">
              <WidgetCoinbaseButton address={account.address} />
              <hr className="w-full h-[2px] border" />
              <div
                className={classNames(
                  theme.currentWidgetTheme === "light"
                    ? "hover:bg-lightWidget-panelColor"
                    : "hover:bg-darkWidget-panelColor",
                  "w-full rounded-br-xl rounded-bl-xl flex justify-center items-center hover:cursor-pointer py-3"
                )}
                onClick={() => {
                  setOnramperState(1)
                }}
              >
                {theme.currentWidgetTheme !== "dark" ? (
                  <img
                    src={images.OnramperLogo}
                    alt="OnramperLogo"
                    className="w-[50%] hover:cursor-pointer"
                  />
                ) : (
                  <img
                    src={images.OnramperLogoWhite}
                    alt="OnramperLogoWhite"
                    className="w-[50%] hover:cursor-pointer"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {onramperState === 1 && (
          <WidgetOnramper account={account} usdValue={usdValue} />
        )}
      </div>
      <div className="flex flex-row gap-2">
        <BackButton
          className="hover:bg-[#888888]/80"
          onClick={() => {
            handleSteps(0)
          }}
        >
          Back
        </BackButton>
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "w-full rounded-md font-bold py-3 hover:cursor-pointer text-[14px]"
          )}
          onClick={() => handleSteps(1)}
        >
          Go to Swap
        </button>
      </div>
    </div>
  )
}
