import { useState, useEffect, useRef } from "react"
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react"
import axios from "axios"

export const SettingModal = ({
  openModal,
  setOpenModal,
  tokenList,
  baseToken,
  targetToken,
}) => {
  const dexScreenerUrl = "https://api.dexscreener.com/latest/dex/tokens/"
  const [approvedTokens, setApprovedTokens] = useState([])
  const [selectedBaseToken, setSelectedBaseToken] = useState(null)
  const [selectedTargetToken, setSelectedTargetToken] = useState(null)
  const [buttonWidth, setButtonWidth] = useState("auto")
  const hiddenSpanRef = useRef(null)
  const [baseTokenBalance, setBaseTokenBalance] = useState(0)
  const [targetTokenBalance, setTargetTokenBalance] = useState(0)
  const [baseTokenUSDValue, setBaseTokenUSDValue] = useState(0)
  const [targetTokenUSDValue, setTargetTokenUSDValue] = useState(0)

  useEffect(() => {
    const list = Object.values(tokenList)
    setApprovedTokens(list)
  }, [tokenList])

  useEffect(() => {
    if (approvedTokens.length > 0) {
      const foundBaseToken = approvedTokens.find(
        (token) => token.symbol === baseToken
      )
      if (foundBaseToken) setSelectedBaseToken(foundBaseToken)

      const foundTargetToken = approvedTokens.find(
        (token) => token.symbol === targetToken
      )
      if (foundTargetToken) setSelectedTargetToken(foundTargetToken)
    }
  }, [approvedTokens, baseToken, targetToken])

  useEffect(() => {
    if (hiddenSpanRef.current) {
      const widths = approvedTokens.map((item) => {
        hiddenSpanRef.current.innerText = item.name
        return hiddenSpanRef.current.offsetWidth
      })
      const maxWidth = Math.max(...widths)
      setButtonWidth(maxWidth + 40)
    }
  }, [approvedTokens])

  // Fetch USD values for base and target tokens
  useEffect(() => {
    const fetchUSDValue = async (token, setUSDValue) => {
      if (token) {
        const { data } = await axios.get(`${dexScreenerUrl}${token.contract}`)
        if (data?.pairs?.length > 0) {
          setUSDValue(data.pairs[0].priceUsd)
        }
      }
    }

    fetchUSDValue(selectedBaseToken, setBaseTokenUSDValue)
    fetchUSDValue(selectedTargetToken, setTargetTokenUSDValue)
  }, [selectedBaseToken, selectedTargetToken, dexScreenerUrl])

  // Calculate targetTokenBalance based on baseTokenBalance
  useEffect(() => {
    if (baseTokenUSDValue > 0) {
      const totalPrice = baseTokenBalance * baseTokenUSDValue
      setTargetTokenBalance(totalPrice / (targetTokenUSDValue || 1)) // Avoid division by zero
    }
  }, [baseTokenBalance, baseTokenUSDValue, targetTokenUSDValue])

  // Calculate baseTokenBalance based on targetTokenBalance
  useEffect(() => {
    if (targetTokenUSDValue > 0) {
      const totalPrice = targetTokenBalance * targetTokenUSDValue
      setBaseTokenBalance(totalPrice / (baseTokenUSDValue || 1)) // Avoid division by zero
    }
  }, [targetTokenBalance, targetTokenUSDValue, baseTokenUSDValue])

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-visible rounded-lg bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-xl font-semibold leading-6"
                >
                  Advanced
                </DialogTitle>

                {/* Base Token Dropdown */}
                <div className="mt-4">
                  <div className="flex items-center justify-between bg-panelColor2-light dark:bg-panelColor2-dark rounded-lg">
                    {approvedTokens.length > 0 && (
                      <Listbox
                        value={selectedBaseToken}
                        onChange={setSelectedBaseToken}
                      >
                        <div className="relative my-2">
                          <ListboxButton
                            style={{
                              width: `${buttonWidth}px`,
                              outline: "none",
                            }}
                            className="relative cursor-pointer rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-0 ring-inset outline-none focus:ring-0 sm:leading-6"
                          >
                            <span className="flex items-center">
                              <img
                                alt=""
                                src={selectedBaseToken?.logo}
                                className="h-10 w-10 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate text-xl">
                                {selectedBaseToken?.symbol}
                              </span>
                            </span>
                          </ListboxButton>

                          <ListboxOptions
                            transition
                            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-panelColor2-light dark:bg-panelColor2-dark py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {approvedTokens.map((item) => (
                              <ListboxOption
                                key={item.symbol}
                                value={item}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9"
                              >
                                <div className="flex items-center">
                                  <img
                                    alt=""
                                    src={item.logo}
                                    className="h-10 w-10 flex-shrink-0 rounded-full"
                                  />
                                  <div className="flex flex-col items-start">
                                    <span className="ml-3 block truncate font-normal group-data-[selectedBaseToken]:font-semibold text-xl">
                                      {item.symbol}
                                    </span>
                                    <span className="ml-3 block truncate font-normal group-data-[selectedBaseToken]:font-semibold text-sm">
                                      {item.name}
                                    </span>
                                  </div>
                                </div>
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </div>
                      </Listbox>
                    )}
                    <div className="px-2 flex flex-col items-end">
                      <input
                        type="number"
                        className="text-2xl bg-panelColor2-light dark:bg-panelColor2-dark ring-0 outline-none rounded-lg border-0 focus:outline-none focus:ring-0 text-right w-40"
                        style={{ outline: "none" }}
                        value={baseTokenBalance}
                        onChange={(e) => {
                          setBaseTokenBalance(Number(e.target.value)) // Use Number to ensure it's a number
                        }}
                        placeholder="Balance"
                      />
                      <p>
                        ~${(baseTokenUSDValue * baseTokenBalance).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Target Token Dropdown */}
                <div className="mt-4">
                  <div className="flex items-center justify-between bg-panelColor2-light dark:bg-panelColor2-dark rounded-lg">
                    {approvedTokens.length > 0 && (
                      <Listbox
                        value={selectedTargetToken}
                        onChange={setSelectedTargetToken}
                      >
                        <div className="relative my-2">
                          <ListboxButton
                            style={{
                              width: `${buttonWidth}px`,
                              outline: "none",
                            }}
                            className="relative cursor-pointer rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-0 ring-inset outline-none focus:ring-0 sm:leading-6"
                          >
                            <span className="flex items-center">
                              <img
                                alt=""
                                src={selectedTargetToken?.logo}
                                className="h-10 w-10 flex-shrink-0 rounded-full"
                              />
                              <span className="ml-3 block truncate text-xl">
                                {selectedTargetToken?.symbol}
                              </span>
                            </span>
                          </ListboxButton>

                          <ListboxOptions
                            transition
                            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-panelColor2-light dark:bg-panelColor2-dark py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {approvedTokens.map((item) => (
                              <ListboxOption
                                key={item.symbol}
                                value={item}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9"
                              >
                                <div className="flex items-center">
                                  <img
                                    alt=""
                                    src={item.logo}
                                    className="h-10 w-10 flex-shrink-0 rounded-full"
                                  />
                                  <div className="flex flex-col items-start">
                                    <span className="ml-3 block truncate font-normal group-data-[selectedTargetToken]:font-semibold text-xl">
                                      {item.symbol}
                                    </span>
                                    <span className="ml-3 block truncate font-normal group-data-[selectedTargetToken]:font-semibold text-sm">
                                      {item.name}
                                    </span>
                                  </div>
                                </div>
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </div>
                      </Listbox>
                    )}
                    <div className="px-2 flex flex-col items-end">
                      <input
                        type="number"
                        className="text-2xl bg-panelColor2-light dark:bg-panelColor2-dark ring-0 outline-none rounded-lg border-0 focus:outline-none focus:ring-0 text-right w-40"
                        style={{ outline: "none" }}
                        value={targetTokenBalance}
                        onChange={(e) => {
                          setTargetTokenBalance(Number(e.target.value)) // Use Number to ensure it's a number
                        }}
                        placeholder="Balance"
                      />
                      <p>
                        ~$
                        {(targetTokenUSDValue * targetTokenBalance).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
