import { Fragment, useState, useRef, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { approvedTokens } from "../../constant"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const WidgetTokenListModal = ({
  showStatus,
  setShowStatus,
  selectedToken,
  isBottomToken,
  setSelectedToken,
}) => {
  const [query, setQuery] = useState("")
  const cancelButtonRef = useRef(null)

  // useEffect(() => {
  //   if (isBottomToken) {
  //     const intervalId = setInterval(() => {
  //       setSplash(true) // Activate the splash effect
  //       setTimeout(() => {
  //         setSplash(false) // Deactivate the splash effect after a short delay
  //       }, 1000) // Adjust the duration of the splash effect as needed
  //     }, 1500) // Adjust the interval duration between each splash effect

  //     return () => {
  //       clearInterval(intervalId) // Clear the interval on component unmount
  //     }
  //   }
  // }, [])

  const theme = useTheme()

  const filteredTokens =
    query === ""
      ? approvedTokens
      : approvedTokens.filter((token) => {
          const lists =
            token.name.toLowerCase().includes(query.toLowerCase()) ||
            token.symbol.toLowerCase().includes(query.toLowerCase()) ||
            token.contract.toLowerCase().includes(query.toLowerCase())
          return lists
        })

  useEffect(() => {
    showStatus && setQuery("")
  }, [showStatus])
  return (
    <>
      <div
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
            : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
          "flex justify-center items-center border border-solid rounded-md p-2 cursor-pointer w-[150px]"
        )}
        onClick={() => setShowStatus(true)}
      >
        <img
          alt="Token Logo"
          src={selectedToken?.logo}
          className="rounded-full w-[20%]"
        />
        <span className="font-semibold text-[12px] text-center w-[60%]">
          {selectedToken?.symbol}
        </span>
        <ChevronDownIcon className="w-[20%]" aria-hidden="true" />
      </div>

      <Transition.Root show={showStatus} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setShowStatus}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    theme.currentWidgetTheme === "light"
                      ? "bg-lightWidget-bgColor text-lightWidget-textColor"
                      : "bg-darkWidget-bgColor text-darkWidget-textColor",
                    "relative transform rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md sm:p-6 h-[80vh] max-h-[600px] overflow-auto"
                  )}
                >
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-semibold leading-6"
                      >
                        Approved token list
                      </Dialog.Title>
                      <div className="mt-5">
                        <input
                          type="text"
                          className={classNames(
                            theme.currentWidgetTheme === "light"
                              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
                              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
                            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
                          )}
                          placeholder="Search name or symbol or address"
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                      <div className="mt-6">
                        {filteredTokens.map((item) => (
                          <div
                            key={item.symbol}
                            className="flex items-center justify-between mb-2 cursor-pointer hover:bg-gray-500 hover:rounded-md px-4"
                            onClick={() => {
                              setSelectedToken(item)
                              setShowStatus(false)
                            }}
                          >
                            <div className="flex items-center">
                              <img
                                alt="logo"
                                src={item.logo}
                                className="w-8 h-auto rounded-full mr-4"
                              />
                              <div>
                                <p className="font-semibold text-start">
                                  {item.symbol}
                                </p>
                                <p className="text-sm text-start">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                            <ChevronRightIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 ">
                    <button
                      type="button"
                      className={classNames(
                        theme.currentWidgetTheme === "light"
                          ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
                          : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
                        "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold"
                      )}
                      onClick={() => {
                        setShowStatus(false)
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
