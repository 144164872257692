import { useState, useEffect } from "react"
import axios from "axios"
import { convertToMegaAndKilo } from "../../utils/formats"
import { BalanceLoader } from "../../components/ContentLoader"
import { getBalanceDirect } from "../../hooks/functions"

export const TokenBalance = ({
  tokenSymbol,
  handleBalance,
  balance,
  status,
  walletAddress,
  tokenList,
}) => {
  const [amount, setAmount] = useState(0)
  const [price, setPrice] = useState(0)
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchPriceAndBalance = async (symbol) => {
      setLoading(true)

      if (Object.keys(tokenList).length > 0) {
        const contract =
          tokenList[symbol] === undefined
            ? tokenList["SOL"]["contract"]
            : tokenList[symbol]["contract"]
        const decimals =
          tokenList[symbol] === undefined
            ? tokenList["SOL"]["decimals"]
            : tokenList[symbol]["decimals"]
        const tokenType = symbol === "SOL" ? "sol" : "spl"
        // Fetch Price
        try {
          const response = await axios.get(
            `https://api.dexscreener.com/latest/dex/tokens/${contract}`
          )
          if (response.data && response.data.pairs) {
            for (const pair of response.data.pairs) {
              if (pair.dexId === "raydium") {
                setPrice(Number(pair.priceUsd))
                break
              }
            }
            // const firstPair = response.data.pairs[0];
            // setPrice(Number(firstPair.priceUsd));
          } else {
            setPrice(0)
          }
        } catch (error) {
          setPrice(0)
        }

        // Set Balance
        if (!status) {
          setAmount(balance / Math.pow(10, decimals))
        } else {
          // Fetch Balance
          try {
            const data = await getBalanceDirect(
              walletAddress,
              contract,
              tokenType
            )
            if (data && data.decimals !== undefined) {
              setAmount(Number(data["amount"] / Math.pow(10, data["decimals"])))
            } else {
              setAmount(0)
            }
          } catch (error) {
            setAmount(0)
          }
        }
      } else {
        setPrice(0)
        setAmount(0)
        // setBalance(0);
      }
      setLoading(false)
    }

    if (tokenSymbol) fetchPriceAndBalance(tokenSymbol)
  }, [tokenSymbol, balance, tokenList, status, walletAddress])

  useEffect(() => {
    const data = amount * price
    setValue(data)
    handleBalance({ amount: amount, price: data })
  }, [amount, price, handleBalance])

  return (
    <div className="flex gap-2 justify-stretch items-center my-1 text-[14px]">
      {loading ? (
        <BalanceLoader />
      ) : (
        <>
          <img
            src={
              Object.keys(tokenList).length > 0
                ? tokenList[tokenSymbol] === undefined
                  ? tokenList["SOL"]["logo"]
                  : tokenList[tokenSymbol]["logo"]
                : ""
            }
            alt="token"
            className="rounded-full w-4 h-4"
          />
          <span>{convertToMegaAndKilo(amount)}</span>
          <span className="flex-grow text-right">
            {convertToMegaAndKilo(value)}$
          </span>
        </>
      )}
    </div>
  )
}
