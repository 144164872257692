import { useNavigate, useParams } from "react-router-dom"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"

import { useTheme } from "../../context/useTheme"
import React from "react"
import { useGetTransaction } from "../../hooks/useGetTransaction"
import { BackButton } from "../../components/Buttons"
import { classNames } from "../../utils/formats"

const TransactionSummary = ({ transaction, txId }) => {
  console.log(transaction)

  const dateTime = new Date(transaction.blockTime * 1000).toLocaleString()

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-semibold mb-4">Transaction Summary </h2>

        <a
          href={`https://solscan.io/tx/${txId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowTopRightOnSquareIcon className="h-6 w-6 inline-block mb-2" />
        </a>
      </div>

      <div className="text-sm text-gray-600 mb-4">Date: {dateTime}</div>

      <div className="grid gap-4">
        {transaction.summaryWithPrices.map((item, index) => (
          <div key={index} className="p-4 border rounded-lg bg-white shadow-md">
            <div className="flex items-center mb-2">
              {item.logo && (
                <img
                  src={item.logo}
                  alt={item.tokenName}
                  className="h-8 w-8 mr-2 rounded-full"
                />
              )}
              <div className="text-lg font-medium">
                {item.tokenName} ({item.symbol})
              </div>
            </div>
            <div className="text-sm text-gray-600">{item.type}</div>
            <div className="text-sm text-gray-600">From: {item.from}</div>
            {item.to && (
              <div className="text-sm text-gray-600">To: {item.to}</div>
            )}
            <div className="text-sm text-gray-600">Amount: {item.amountUi}</div>
            <div className="text-sm text-gray-600">
              Value: ${item.value.toFixed(2)}
            </div>
            <div className="text-sm text-gray-600">Price: ${item.price}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const Transaction = ({ account }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { txId } = useParams() // Destructure the parameter from useParams
  const { transaction, loading } = useGetTransaction(account, txId)

  return (
    <div className="flex flex-col h-full p-4 mb-30 pb-30">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="text-gray-500">Loading...</div>
        </div>
      ) : (
        <TransactionSummary transaction={transaction} txId={txId} />
      )}
      <div className="w-full p-4 flex justify-center">
        <BackButton
          onClick={() => navigate(-1)}
          className={classNames(
            "w-[400px]",
            theme.currentTheme === "dogTheme"
              ? "bg-dogTheme-primary"
              : theme.currentTheme === "lightTheme"
              ? "bg-lightTheme-gradient"
              : "bg-darkTheme-gradient",
            "bg-[#ddd]"
          )}
        >
          Back
        </BackButton>
      </div>
    </div>
  )
}
