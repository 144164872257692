import React, { useEffect, useState } from "react"
import { images } from "../../assets"
import { useSearchParams } from "react-router-dom"
import { applyActionCode, sendEmailVerification } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { auth } from "../../utils/firebase"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const WidgetVerification = () => {
  const [loading, setLoading] = useState(true)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(null)
  const [buttonText, setButtonText] = useState("Resend verification email")
  const [verified, setVerified] = useState(false)
  const [searchParams] = useSearchParams()
  const [seconds, setSeconds] = useState(5)

  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    const oobCode = searchParams.get("oobCode")
    const mode = searchParams.get("mode")

    if (!oobCode || mode !== "verifyEmail") {
      return
    }

    const verifyEmail = async () => {
      try {
        console.log("Applying action code:", oobCode)
        await applyActionCode(auth, oobCode)
        setVerified(true)
        console.log("Email verified, navigating to /swap")
        navigate("/swap", { replace: true })
        //refresh page if not redirected. Update countdown timer
        const interval = setInterval(() => {
          setSeconds((prev) => prev - 1)
        }, 1000)
        setTimeout(() => {
          clearInterval(interval)
        }, 5000)
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      } catch (err) {
        console.error("Error verifying email:", err)
        setError(
          `Invalid or expired verification code. Please try again. ${err.message}`
        )
      } finally {
        setLoading(false)
      }
    }

    verifyEmail()
  }, [searchParams, navigate])

  const handleSendEmailVerification = async () => {
    if (auth.currentUser && !emailSent) {
      try {
        setButtonText("Sending email...")
        console.log("Sending verification email")
        await sendEmailVerification(auth.currentUser)
        console.log("Email sent")
        setButtonText("Email sent")
        setEmailSent(true)
        setLoading(false)
        setError(null)
      } catch (error) {
        console.error("Error sending email verification:", error)
        setError(error.message)
      }
    }
  }

  return (
    <div className="flex w-full h-full px-4 py-6">
      <div className="flex w-full flex-col my-auto items-center gap-2">
        <img src={images.MemexLogo} className="w-2/3" alt="" />
        <div className="text-center">
          <h1 className="text-xl font-bold tracking-tight pt-5">
            To proceed, please verify your email.
          </h1>
          <p className="text-[16px] pt-5">
            If you didn't receive the email, please check your spam folder or
            try again later.
          </p>
          {!verified ? (
            <p className="pt-5">
              <button
                disabled={emailSent}
                className={classNames(
                  theme.currentWidgetTheme === "light"
                    ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
                    : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
                  "rounded-full py-2 w-full"
                )}
                onClick={handleSendEmailVerification}
              >
                {buttonText}
              </button>
            </p>
          ) : (
            <p className="text-green-500 pt-5">
              Email verified! Refresh page if you are not redirected in{" "}
              {seconds} seconds
            </p>
          )}
          <p className="text-[16px] pt-5">
            After verifying your email, please proceed to the Sign In page.
          </p>
          <p className="pt-5">
            <button
              disabled={emailSent}
              className={classNames(
                theme.currentWidgetTheme === "light"
                  ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
                  : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
                "rounded-full py-2 w-full"
              )}
              onClick={() => navigate("/swap/signin")}
            >
              Continue Signing In
            </button>
          </p>
          <div>{error && <p className="text-red-500">{error}</p>}</div>
        </div>
      </div>
    </div>
  )
}
