import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../../utils/firebase"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"

export const WidgetForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [isSent, setIsSent] = useState(false)
  const navigate = useNavigate()

  const theme = useTheme()

  const sendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email)
      setIsSent(true)
    } catch (error) {
      console.error("Error sending password reset email: ", error)
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      sendResetEmail()
    }
  }

  return (
    <>
      <p className="text-xl imd:text-2xl text-center py-3">
        Forgot your password?
      </p>
      <p className="text-base pt-5 text-left ml-2">Email</p>
      <div className="flex mt-3 rounded-md justify-between items-center">
        <input
          type="email"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="achithebest@example.com"
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={handleKeyUp}
        />
      </div>
      {isSent && (
        <p className="my-5 text-center">
          The email has been sent. Check your Inbox!
        </p>
      )}
      <div className="w-full flex justify-center gap-2">
        <button
          className="bg-[#bbbbbb] hover:bg-[#bbbbbb]/80 rounded-full py-2 w-1/2 mt-4"
          onClick={() => navigate("/swap/signin")}
        >
          Back
        </button>
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "rounded-full w-1/2 py-3 mt-3"
          )}
          onClick={sendResetEmail}
        >
          Send
        </button>
      </div>
    </>
  )
}
