export const AMMBalance = ({ tokenSymbol, tokenList, balance }) => {
  let value = 0
  if (Object.keys(tokenList).length > 0 && tokenList[tokenSymbol]) {
    value = balance / Math.pow(10, tokenList[tokenSymbol]["decimals"])
  }
  return (
    <div className="flex gap-2 justify-stretch items-center my-1 text-[14px]">
      <img
        src={
          Object.keys(tokenList).length > 0
            ? tokenList[tokenSymbol ? tokenSymbol : "SOL"]["logo"]
            : ""
        }
        alt="token"
        className="rounded-full w-4 h-4"
      />
      <span>{value}</span>
    </div>
  )
}
